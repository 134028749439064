import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'amfe-flexible'
import '../mock/index'

import { Lazyload,Toast } from 'vant';
import "./assets/css/common.scss";
import sensors from "@/sensors/sensors";
const app = createApp(App);
app.config.globalProperties.$sensors = sensors;

app.use(Lazyload, {
    lazyComponent: true,
});
app.use(Toast)
createApp(App).use(store).use(router).use(Lazyload).mount('#app')

