import request from '@/utils/request'
import { AxiosPromise } from 'axios'
const classApi = {
  SendCode:'/api/app/newSmsCode',
  Login: '/api/app/login',
  BrowseLog: '/hd-api/os/activity/browse-log',
  ActivityIsBuy: '/hd-api/os/activity/activity-is-buy',
  DownOrder: '/hd-api/os/activity/pay-activity'
}
//
// export function ActivityInfo(params: {
//   id: number
// }): AxiosPromise {
//   return request({
//     url: classApi.ActivityInfo,
//     method: 'get',
//     data: params,
//   })
// }

export function ActivityInfo(id:number): AxiosPromise {
  return request({
    url: '/hd-api/os/activity/info?id=' + id,
    method: 'get'
  })
}

export function smsCode(params:{
  mobile:string
  sms_type:string,
  anonymous_id:string,
}): AxiosPromise {
  return request({
    url: classApi.SendCode,
    method: 'POST',
    data: params,
  })
}

export function Login(params: {
  mobile: string
  sms_code: string,
  type: number,
  client: number,
  user_source:'渠道',
}): AxiosPromise {
  return request({
    url: classApi.Login,
    method: 'post',
    data: params,
    headers:{
      schoolid: 2
    }
  })
}

export function BrowseLog(params: {
  activityId: string
  type: string
}): AxiosPromise {
  return request({
    url: classApi.BrowseLog,
    method: 'post',
    data: params
  })
}

export function ActivityIsBuy(params: {
  activityId: number
}): AxiosPromise {
  return request({
    url: classApi.ActivityIsBuy,
    method: 'post',
    data: params,
  })
}

export function DownOrder(params: {
  activityId: number,
  activityItemId: string

}): AxiosPromise {
  return request({
    url: classApi.DownOrder,
    method: 'post',
    data: params,
  })
}

export function CourseList(id:number): AxiosPromise {
  return request({
    url: '/hd-api/os/activity/select-course?id=' + id,
    method: 'get'
  })
}