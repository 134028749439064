/* eslint-disable @typescript-eslint/no-unused-vars */
import { Module } from 'vuex'
import router from '@/router'
import { login, fetchUserInfo, logout } from '@/api/user'
import { Toast } from 'vant'
export interface UserState {
  token: string
  info: Record<string, string | number>
  openId:'',
  feePlanId:''
}

const user = {
  state: {
    token: '',
    info: {},
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    setOpenId(state,data){
      state.openId = data
    },
    SET_SHOWPLAN:(state,show)=>{
      state.feePlanId = show
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(response => {
            const result = response.data
            commit('SET_TOKEN', result.remember_token)
            commit('SET_INFO', result)
            Toast('登录成功')
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        fetchUserInfo()
          .then(response => {
            const result = response.data
            commit('SET_INFO', result)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 退出
    Logout({ commit, state }) {
     
      commit('SET_TOKEN', '')
      commit('SET_INFO', {})
      sessionStorage.removeItem('feeInfo')
      localStorage.removeItem('user')
      if(location.href.includes('/noData')){
        router.replace({
          name:'Login',
        })
      }else{
        router.push({
          name:'Login',
        })
      }
      
    },
  },
}

export default user as unknown as Module<UserState, Record<string, unknown>>
