import sensors from "sa-sdk-javascript";
import store from "@/store";
sensors.init({
  server_url:  process.env.VUE_APP_SERVER_ENV == 'test' || process.env.VUE_APP_SERVER_ENV == 'development'
      ?"https://shence-bd.hadecj.com/sa?project=default"
      : "https://shence-bd.hadecj.com/sa?project=production",
  show_log: process.env.VUE_APP_SERVER_ENV == 'test' || process.env.VUE_APP_SERVER_ENV == 'development',
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  app_js_bridge:true,
  send_type: "beacon",
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: "default",
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: "default",
  },
});

//公共属性
sensors.registerPage({
  platform_type: "h5",
  product_line: "哈德网校",
});

if(localStorage['user'] && localStorage['user'].token){
  sensors.login(localStorage["user"].id);
}

sensors.quick("autoTrack");

let sensorsList = {
  //点击获取短信验证码
  clickGetCode(businessType) {
    sensors.track("clickGetCode", {
      business_type: "验证码登录",
    });
  },

  //点击登录/注册按钮
  clickRegister() {
    sensors.track("clickRegister");
  },
};
export default { sensors, sensorsList };
