import { Module } from 'vuex'
import router from '@/router'
import {BrowseLog, Login, ActivityIsBuy, DownOrder, ActivityInfo} from '@/api/classPurchase'
import { Toast } from 'vant'
import sensors from "@/sensors/sensors";
export interface classPurchaseState {
    activityId: number, //活动ID
    gainClassShow: boolean,   //已领取过活动弹框
    wxModelShow: boolean,   //微信浏览器蒙层
    userInfo: Record<string, string | number>, //用户信息
    currentClass: Record<string, string | number>, //当前课程
    activityInfo: Record<string, string | number>, //活动详情
}

const classPurchase = {
    state: {
        activityId: null,
        gainClassShow: false,
        wxModelShow: false,
        userInfo: null,
        classList: [],
        currentClass: {
            title: null,
            isBuy: 0
        },
        activityInfo:{
            indexImg: null,
            indexListStyle: null,
            bottomTitle: null,
            bottomTitleStyle: null,
            background: null,
            gainSuccessImg:null
        }
    },
    mutations: {
        SET_ACTIVITED: (state, id) => {
            state.activityId = id
        },
        SET_ACTIVITYTED: (state, activityInfo) => {
            state.activityInfo = activityInfo
        },
        SET_GAIN_CLASS_SHOW: (state, gainClassShow) => {
            state.gainClassShow = gainClassShow
        },
        SET_WX_MODEL_SHOW: (state, wxModelShow) => {
            state.wxModelShow = wxModelShow
        },
        SET_INFO: (state, info)=> {
            state.userInfo = info
        },
        SET_CURRENT_CLASS: (state, currentClass)=> {
            state.currentClass = currentClass
        },
        SET_CLASS_LIST: (state, classList)=> {
            state.classList = classList
        }
    },

    actions: {
        //设置活动ID
        handleSetActivited({ commit, state }, id){
            commit('SET_ACTIVITED', id)
        },
        // 是否领取过课程弹框
        handleSetGainClassShow({ commit, state }, gainClassShow){
            commit('SET_GAIN_CLASS_SHOW', gainClassShow)
        },
        // 微信浏览器蒙层
        handleSetWxModelShow({ commit, state }, wxModelShow){
            commit('SET_WX_MODEL_SHOW', wxModelShow)
        },
        // 设置当前课程
        handleSetCurrentClass({ commit, state }, currentClass){
            commit('SET_CURRENT_CLASS', currentClass)
        },
        // 设置当前课程
        handleSetClassList({ commit, state }, classList){
            commit('SET_CLASS_LIST', classList)
        },

        // 浏览记录
        handleBrowseLog({ commit, state },activitedInfo) {
            return new Promise(resolve => {
                BrowseLog(activitedInfo)
                    .then(() => {
                        resolve(true)
                    })
                    .catch(err => {
                        console.log('BrowseLog fail:', err)
                    })
                // .finally(() => {})
            })
        },

        // 登录
        handleLogin({ commit, state, dispatch },info) {

            return new Promise(resolve => {
                Login(info)
                    .then((response) => {
                        const result = response.data
                        commit('SET_TOKEN', result.remember_token)
                        commit('SET_INFO', result)
                        sensors.sensors.login(String(result.id));
                        dispatch('handleActivityIsBuy');
                        Toast('登录成功')
                        resolve(response)
                    })
                    .catch(err => {
                        console.log('Login fail:', err)
                    })
                // .finally(() => {})
            })
        },

        // 获取活动详情
        handleActivityInfo({ commit, state, dispatch },info) {
            return new Promise(resolve => {
                ActivityInfo( info )
                    .then((response) => {
                        commit('SET_ACTIVITYTED', response)
                        resolve(response)
                    })
                    .catch(err => {
                        console.log('ActivityInfo fail:', err)
                    })
                // .finally(() => {})
            })
        },

        // 课程是否购买
        handleActivityIsBuy({ commit, state, dispatch },info) {
            return new Promise(resolve => {
                ActivityIsBuy({activityId: state.activityId})
                    .then((response :any) => {
                        if(response.isBuy){
                            const classList= state.classList
                            classList.forEach(item=>{
                                if( item.courseBasisId == response.courseBasisId){
                                    item.isBuy = 1
                                    item.activityId = state.activityId
                                    commit('SET_CURRENT_CLASS', item)
                                    router.push({ 'name': 'GainSuccessClassPurchase', query: {currentClass: JSON.stringify( item)}})
                                }
                            })
                        }else{
                            dispatch('handleDownOrder');
                        }
                        resolve(response)
                    })
                    .catch(err => {
                        console.log('ActivityIsBuy fail:', err)
                    })
                // .finally(() => {})
            })
        },
        // 课程下单
        handleDownOrder({ commit, state },info) {
            return new Promise(resolve => {
                const data={
                    activityId: state.activityId,
                    activityItemId: state.currentClass.activityItemId
                }
                DownOrder(data)
                    .then((response :any) => {
                        router.push({ 'name': 'GainSuccessClassPurchase', query: {currentClass: JSON.stringify(state.currentClass)}})
                        resolve(response)
                    })
                    .catch(err => {
                        console.log('ActivityIsBuy fail:', err)
                    })
                // .finally(() => {})
            })
        },

    },
}

export default classPurchase as Module<classPurchaseState, Record<string, unknown>>
