
    import {defineComponent, toRefs,  reactive} from 'vue';
    import { Loading } from 'vant';
    export default defineComponent({
        name: "",
        components: {Loading},
        setup() {
            const reactiveData = reactive({});

            return {
                ...toRefs(reactiveData),
            };
        },
    });
