import { Module } from 'vuex'
import { getSetting, getOssSignatureInfo } from '@/api/setting'

export interface ISettingState {
    otherSetting: Record<string, string | number>, //设置信息
    ossSignature: Record<string, string | number>,
}

const settings = {
    state: {
        ossSignature: null,
        otherSetting: null
    },
    mutations: {
        setOtherSetting (state, setting) {
            state.otherSetting = setting;
        },
        setOssSignature (state, config) {
            state.ossSignature = config;
        },
    },
    actions: {
        async getOtherSetting ({ commit }) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise<void>(async (resolve) => {
                const setting = await getSetting()
                commit("setOtherSetting", setting.data);
                resolve();
            });
        },
        async getOssSignature ({ commit }) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise<void>(async (resolve) => {
                const config = await getOssSignatureInfo()
                commit("setOssSignature", config);
                resolve();
            });
        }
    }

}

export default settings as Module<ISettingState, Record<string, unknown>>