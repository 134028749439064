
import { useStore } from 'vuex'
import Loading from '@/components/loading.vue'
import { defineComponent, computed,nextTick } from 'vue';
import Vconsole from 'vconsole';
if (process.env.VUE_APP_SERVER_ENV != 'production') {
	new Vconsole()
}
export default defineComponent({
	name: 'App',
	components: {
		Loading,
	},
	setup () {
		const store = useStore()
		console.log(store)
		return {
			loading: computed(() => store.state.loading)
		}
	},
	beforeCreate () {
		//获取appToken
		const store = useStore()
		// Toast('测试测试')
		window["getAppToken"] = function (res) {
			console.log("获取到的token:", res)
			// Toast(res)

			if (res && res.length) {
				store.commit('SET_TOKEN', res)
			}
		}

	},
	async mounted () {
		await nextTick();
		setTimeout(() => {
			if (this.$store.state.user['token'] && this.$store.state.user['token'].length) {
				this.$store.dispatch('getOtherSetting');
				this.$store.dispatch('getOssSignature');
			}
		}, 200);
	}
})

