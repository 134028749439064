// 正式
export default {
  env: 'production',
  mock: false,
  title: '生产',
  baseUrl: 'http://localhost:8080', // 正式项目地址
  baseApi: 'https://api.hadecj.com', // 正式api请求地址
  APPID: 'wx74aac87d227a6750',
  APPSECRET: 'xxx',
  $cdn: 'https://imgs.solui.cn',
}
