import { createStore } from 'vuex'
import test, { TestState } from './modules/test'
import user from './modules/user'
import classPurchase from './modules/classPurchase'
import settings from './modules/settings'
import createPersistedState from 'vuex-persistedstate'

export interface State {
  test: TestState
}

const store = createStore({
  modules: {
    test,
    user,
    classPurchase,
    settings
  },
  state:{
    loading:false,
    showPay:false,
    feePlanId:0,
    currentRoute:{},
  },
  mutations: {
    showLoading(state){
        state.loading = true
    },
    hideLoading (state) {
        state.loading = false
    },
    showPaying(state){
      state.showPay = true
    },
    hidePaying(state){
      state.showPay = false    
    },
   
    setRoute(state,data ){
      state.currentRoute = {...data}
    },
   
  },
  plugins: [
    createPersistedState({
      // 默认存储在localStorage 现改为sessionStorage
      // storage: window.sessionStorage,
      // 本地存储数据的键名
      key: 'user',
      // 指定需要存储的模块，如果是模块下具体的数据需要加上模块名称，如user.token
      paths: ['user',]
    })
  ]
})

export default store
