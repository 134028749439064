import request from '@/utils/request'
import { AxiosPromise } from 'axios'

const userApi = {
  Login: '/api/app/login',
  Logout: '/auth/logout',
  UserInfo: '/api/app/userInfo',
  SendCode:'/api/app/newSmsCode'
}

export function login(params: {
  userName: string
  password: string
  client:number
}): AxiosPromise {
  return request({
    url: userApi.Login,
    method: 'post',
    data: params,
  })
}

export function fetchUserInfo(): AxiosPromise {
  return request({
    url: userApi.UserInfo,
    method: 'get',
  })
}
export function logout(): AxiosPromise {
  return request({
    url: userApi.Logout,
    method: 'POST',
  })
}

export function smsCode(params:{
  mobile:string
  sms_type:string,
}): AxiosPromise {
  return request({
    url: userApi.SendCode,
    method: 'POST',
    data: params,
  })
}

