import request from '@/utils/request'
import { AxiosPromise } from 'axios'
export function getSetting(): AxiosPromise {
    return request({
        url: `/api/config/other_setting`,
        method: 'get',
    })
}
export function getOssSignatureInfo(): AxiosPromise {
    return request({
        url: `/hd-api/os/resources/aliyun/oss/signature`,
        method: 'get',
    })
}

// 钉钉JsApi鉴权
export function getDingSignatureInfo(data): AxiosPromise {
    return request({
        url: `/hd-api/os/login/dingtalk/js-api`,
        method: 'post',
        data:data
    })
}