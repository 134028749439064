/**
 * @description 封装 axios
 */

import store from '@/store'
import axios from 'axios'
import { Toast } from 'vant'
import config  from '../config/index'
import router from '@/router'
const service = axios.create({
  baseURL: config.baseApi,
  timeout: 5000,
  withCredentials: false, // send cookies when cross-domain requests
})

// Request interceptors
service.interceptors.request.use(
  config => {
    // 在此处添加请求头等，如添加 token
    if (store.state.user['token']) {
      config.headers['Authorization'] = `Bearer ${store.state.user['token']}`
    }
    config.headers['DeviceType'] = `h5`
    config.headers['schoolid'] = 2
    store.commit('showLoading')
    return config
  },
  error => {
    Promise.reject(error)
  }
)

/**
 * 异常拦截处理器
 * @param error
 * @returns
 */
const errorHandler = error => {
  store.commit('hideLoading')
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = error.response.data.message
        break
      case 401:
        store.commit('SET_TOKEN', null)
        error.message = ''
        router.push({ path: '/login' })
        break
      case 403:
        error.message = '拒绝访问'
        break
      case 404:
        error.message = `请求地址出错: ${error.response.config.url}`
        break
      case 405:
        error.message = '请求方法未允许'
        break
      case 408:
        error.message = '请求超时'
        break
      case 500:
        error.message = '服务器内部错误'
        break
      case 501:
        error.message = '服务未实现'
        break
      case 502:
        error.message = '网络错误'
        break
      case 503:
        error.message = '服务不可用'
        break
      case 504:
        error.message = '网络超时'
        break
      case 505:
        error.message = 'HTTP版本不受支持'
        break
      default:
        error.message = `连接错误: ${error.message}`
    }
  } else {
    if (error.message == 'Network Error') {
      error.message == '网络异常，请检查后重试！'
    }
    // error.message = '连接到服务器失败，请联系管理员'
    error.message = ''
  }
  error.message&&Toast(error.message)
  // store.dispatch('clearAuth')
  return Promise.reject(error)
}

// Response interceptors
service.interceptors.response.use(response => {
  store.commit('hideLoading')
  if(response.data.code==202){
    Toast('请先登录')
    router.push({ path: '/login' })
  }else if(response.data.code==201){
    Toast(response.data.msg)
  }else{
    return response.data
  }
  
}, errorHandler)

export default service
